import React from "react";
import { Layout } from "../Component/Layout";
import SigninC from "../Component/SignIn/SigninC";

const Signin = () => {
  return (
    <>
      <Layout Component={SigninC} />
    </>
  );
};

export default Signin;
