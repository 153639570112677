import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { changePassword } from '../../shared/services/api';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup';
import queryString from 'query-string'
import { ToastContainer, toast } from 'react-toastify';
// interface ParamTypes {
//   token: string
// }
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ResetPassword = () => {
  const history = useHistory();
  const [success, setSuccess] = useState();
  const [userInfo, setUserInfo] = useState<any>();
  const [tokenValue, setTokenValue] = useState<string>('');



  let query = useQuery();
  const token: any = query.get("token")



  // password match
  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is mendatory'),
    confirmPwd: Yup.string()
      .required('Password is mendatory')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { register, handleSubmit, reset, formState } = useForm(formOptions)
  const { errors } = formState

const [resetPass,setResetPass]=useState();
const [errorMsg,setErrorPass]=useState();

  const onSubmit = (data: any) => {
    const { password } = data;
    const token: any = query.get("token")
    changePassword(token, password).then(data => {
      console.log(data);

      if (data.error) {
        setErrorPass(data.message)
        errorSucces()
      }
      else {
        history.push('/')
        setResetPass(data.message)
        resetSucces();
      }

    })
  }

  const resetSucces = () => {
    const resetPasss=resetPass;
    toast(resetPasss)
  };
  const errorSucces = () => {
    const errorMssg=errorMsg;
    toast(errorMssg)
  };
  return (
    <div className="bg_color">
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <h2 className="active"> Reset Password </h2>

          <div className="fadeIn first">
            <img src="../logo.png" id="icon" alt="User Icon" />
          </div>
          <p>{success}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              type="password"
              className={`form-control fadeIn second ${errors.password ? 'is-invalid' : ''}`}
              placeholder="Enter Your Password"
              {...register('password', { required: true })}
            />

            <input
              type="password"
              className={`form-control fadeIn third ${errors.confirmPwd ? 'is-invalid' : ''}`}
              placeholder="Confirm Password"
              {...register('confirmPwd', { required: true })}
            />
            <p className='text-danger'>{errors.confirmPwd ? 'password does not match' : ''}</p>
            <button
              type="submit"
              className="fadeIn fourth basic_button"
            >
              Reset Password
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  )
}

export default ResetPassword