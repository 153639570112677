import React from "react";

//compoent props
type LayoutProps = {
  Component: React.ComponentType;
};

export const Layout = ({ Component }: LayoutProps) => {
  return (
    <>
      <Component />
    </>
  );
};
