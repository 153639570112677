import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { resetPassword, signin, validate } from '../../shared/services/api';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { ToastContainer, toast } from 'react-toastify';

// import css
import './SigninC.css';
import { useMountedLayoutEffect } from 'react-table';

const SigninC = () => {
  const [userInfo, setUserInfo] = useState<any>();
  const [userEmail, setuserEmail] = useState<any>();
  const [tokenValue, setTokenValue] = useState<string>('');
  const history = useHistory();
  const [error, setError] = useState(0);

  //use react form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    let user = localStorage.getItem('user_info');
    let token = localStorage.getItem('auth_token');

    if (user) {
      setUserInfo(JSON.parse(user))
    }
    if (token) {
      setTokenValue(token)
    }
	if (user && token) {
		validate().then((res) => {
			if (res.message === 'validated') {
				history.push(`/account/management`);
			}
		})
	}
  }, []);


  const [open, setOpen] = useState(false);
  const [messageNotification, setMessageNotification] = useState();

  let onSubmit = (data: any) => {
    signin(data)
      .then((data) => {
        if (data.auth_token) {
          setError(0);
          let { auth_token, ...userInfo } = data;

          localStorage.setItem('auth_token', auth_token);
          localStorage.setItem('user_info', JSON.stringify(userInfo));

          history.push(`/account/management`);
        }
        else {
          setError(1);
        }

      })
      .catch((err) => {
        throw err;
      });
    setValue('email', '', { shouldValidate: false });
    setValue('password', '', { shouldValidate: false });
  };
  const showError = () => {
    // console.log(error);
    return (
      <span
        className=""
        style={{ display: error ? "" : "none" }}
      >
        Invalid user Information
      </span>
    );
  };

  const closeModal = () => {
    setOpen(false);
  };

  let forgetPassword = (data: any) => {
    const { email } = data;
    resetPassword(email).then(data => {
      console.log('date :', data);
      toast(data.message)
      closeModal();
    })
  }

  return (
    <>
      <div className="bg_color">
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <h2 className="active"> Sign In </h2>

            <div className="fadeIn first">
              <img src="../logo.png" id="icon" alt="User Icon" />
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                required
                type="text"
                className="fadeIn second"
                placeholder="Enter Your Email Address"
                {...register('email', { required: true })}
              />
              <input
                required
                type="password"
                className="fadeIn third"
                placeholder="Enter Your Password"
                {...register('password', { required: true })}
              />
              <p className='text-danger'>{showError()}</p>
              <button
                type="submit"
                className="fadeIn fourth basic_button"
              >
                {' '}
                Log In
              </button>
            </form>
            <div id="formFooter">
              <a className="underlineHover" onClick={() => setOpen(o => !o)}>
                Forgot Password?
              </a>
            </div>
            <Popup className='popupEmail' open={open} closeOnDocumentClick onClose={closeModal}>
              <form id="emailForm" onSubmit={handleSubmit(forgetPassword)}>
                <p className='forgetText'>Forget Password</p>
                <input type="email" className="fadeIn third" placeholder='enter your email' {...register('email', { required: true })} />
                <button type='submit' className='btn emailBtn'>Submit</button>
              </form>

            </Popup>
            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default SigninC;
