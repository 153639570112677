import React from 'react';
import TableC from '../Component/Table/TableC';
import { useParams } from 'react-router-dom';
import { TableColumn } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';

const columns : TableColumn[] = [
	{
		label: 'Name',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
		label: 'Anchor Text',
		id: 'anchor_text',
		type: 'string',
	},
	{
		label: 'Link Type',
		id: 'link_type',
		type: 'string',
	},
	{
		label: 'Linked Domain',
		id: 'linked_domain',
		type: 'string',
		editable: false
	},
	{
		label: 'DR',
		id: 'dr',
		type: 'number',
	},
	{
		label: 'Price',
		id: 'price',
		type: 'number',
	},
	{
		label: 'Live URL',
		id: 'live_url',
		type: 'string',
	},
	{
		label: 'Date',
		id: 'created_at',
		type: 'date',
	}
];	

type URLParams = {
	id: string;
};

const Historical = () => {
	let { id } = useParams<URLParams>();
	const showError = () => {
		return (
			
			<div className="col-md-12 text-center mt-5">
				<h2 className="text-danger">Please Select A Sub Menu</h2>
			</div>

		);
	};
  
  return (
    <>	  
        {id ? <TableC id={id} type='historical' editable={true} useFilters={false} useGroups={true} columns={columns} /> : showError()}
    </>
  );
};

export default Historical;
