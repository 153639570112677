export enum SORT_TYPE {
  NA = 'NA',
  ASCE = 'ASCE',
  DESC = 'DESC',
}

export enum OPTIONS {
  CONTAIN = 'contain',
  DOES_NOT_CONTAIN = 'notcontain',
  IS = 'is',
  IS_NOT = 'isnot',
  IS_EMPTY = 'isempty',
  IS_NOT_EMPTY = 'isnotempty',
  LESS_THEN_OR_EQUAL = 'lessthenorequal',
}
export enum COLUMN_TYPE {
  EQUAL = '=',
  NOT_EQUAL = '!=',
  LESS_EQUAL = '>=',
  LESS = '>',
  GREATER_EQUAL = '<=',
  GREATER = '<',
  CONTAIN = 'contain',
  DOES_NOT_CONTAIN = 'notcontain',
  IS = 'is',
  IS_NOT = 'isnot',
  IS_EMPTY = 'isempty',
  IS_NOT_EMPTY = 'isnotempty',
}
