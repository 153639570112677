import React, { useEffect, useState } from 'react'
import { Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import {
    faEnvelope,
    faEye,
} from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';
//import css
import './AccountManagement.css'
import Popup from 'reactjs-popup';
import { 
	getUserInfo, 
	addNewUser, 
	deleteUser, 
	updateUserPassword, 
	updateUser, 
	userLogout, 
	getMondayStatus,
	changeMondayStatus
} from '../../shared/services/api';
import { useHistory } from 'react-router-dom';
import dayjs from "dayjs";
import { ToastContainer, toast } from 'react-toastify';




const AccountManagement = () => {

    const [userInfo, setUserInfo] = useState<any>();
    const [tokenValue, setTokenValue] = useState<string>('');
    const [user, setUser] = useState<any>();
    const history = useHistory();
    const [userName, setUserName] = React.useState<string>('');
    const [downloadFile, setDownload] = React.useState<boolean>(false);
    const [makingMsg, setMakingMsg] = React.useState<string>('');
    //update user
    const [updateUserName, setUpdateUserName] = useState<string>("");
    const [updateUserRole, setUpdateUserRole] = useState<string>("");
    const [updateUserEmail, setUpdateUserEmail] = useState<string>("");
    const [updateUserID, setUpdateUserID] = useState<string>("");

    //add new user state 
    const [newUserName, setNewUserName] = useState<string>("");
    const [newUserEmail, setNewUserEmail] = useState<string>("");
    const [newUserRole, setNewUserRole] = useState<string>("");


    const [loading, setLoading] = React.useState<boolean>(false);

    //delete user id
    const [deleteUserId, setDeleteUserId] = useState<string>("");

    //update password
    const [updatePassword, setUpdatePassword] = useState<string>("");


    const [update, setUpdate] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(false);
    const [add, setAdd] = useState(false);


    // Password
    const [passwordInput, setPasswordInput] = useState("");
    const [poorPassword, setPoorPassword] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [strongPassword, setStrongPassword] = useState(false);
    const [passwordError, setPasswordErr] = useState("");
    const [passwordType, setPasswordType] = useState("text");
    const [mondayStatus, setMondayStatus] = useState<boolean>(true);
	const [modayWarning, setMondayWarning] = useState<boolean>(false);

    const [passwordStrong, setPasswordStrong] = useState(1);
    // const [passwordInput, setPasswordInput] = useState("");

    useEffect(() => {
        let user = localStorage.getItem('user_info');
        let token = localStorage.getItem('auth_token');

        if (user) {
            setUserInfo(JSON.parse(user))
        }
        if (token) {
            setTokenValue(token)
        }
    }, []);

    // Api calls
    useEffect(() => {
        if (userInfo && userInfo.role_id === 2) {
			getMondayStatus().then((res) => {
				setMondayStatus(res.data)
			})
		}
    }, [userInfo])

    useEffect(() => {
        getUserInfo().then((data) => {
            setUser(data.data)
        });
    }, [])

    const closeMondayModal = () => {
        setMondayWarning(false);
    };

	const updateMonday = () => {
		changeMondayStatus().then((res) => {
			if (res.message === 'updated') {
				setMondayStatus(!mondayStatus);
				window.location.reload();
			} else {
				toast('Error: Could not change Monday status');
			}
			setMondayWarning(false);
		})
	}
    
	const closeModal = () => {
        setUpdate(false);
        setDeleteInfo(false);
        setAdd(false);
    };


    //create new user 
    const clickAddNewUser = () => {
        let obj = {
            "name": newUserName,
            "email": newUserEmail,
            "role_id": newUserRole.toLowerCase() === "admin" ? 2 : 1
        }
        if (newUserName.length > 0 && newUserEmail.length > 0 && newUserRole.length > 0) {
            addNewUser(obj).then(data => {
                if (data.error === false) {
                    toast(data.data)
                }
                else {
                    toast('Somwthing worng!!')
                }

                getUserInfo().then((data) => {
                    setUser(data.data)
                    setAdd(false);
                    setNewUserName("")
                    setNewUserEmail("")
                    setNewUserRole("")
                });
            })
        }

    }
    ///delete user
    const clickDeleteUser = () => {
        setDeleteInfo(o => !o)
        deleteUser(deleteUserId).then(data => {
            if (data.error === false) {
                toast(data.data)
            }
            else {
                toast('Somwthing worng!!')
            }
            getUserInfo().then((data) => {
                setUser(data.data)
                setDeleteInfo(false);
            });
        })
    }
    //update password
    const clickUpdatePassword = () => {
        updateUserPassword(userInfo.id, updatePassword).then(data => {
            if (data.error === false) {
                toast(data.data)
            }
            else {
                toast('Somwthing worng!!')
            }
            setUpdatePassword("");
        })
    }
    //set update user info
    const setUpdateUserBaseInfo = (user: any) => {
        setUpdateUserName(user.name)
        setUpdateUserEmail(user.email)
        setUpdateUserRole(user.role)
        setUpdateUserID(user.id)
        setUpdate(o => !o)
    }
    //update user info
    const clickUpdateUser = () => {
        let obj: any = {}

        for (let i = 0; i < user.length; i++) {

            if (user[i].id === updateUserID) {
                if (user[i].name !== updateUserName) {
                    obj['name'] = updateUserName;
                }
                if (user[i].email !== updateUserEmail) {
                    obj['email'] = updateUserEmail;
                }
                if (user[i].role !== updateUserRole) {
                    obj['role'] = updateUserRole.toLowerCase() === "admin" ? 2 : 1;
                }
            }
        }

        updateUser(updateUserID, obj).then(data => {
            if (data.error === false) {
                toast(data.data)
            }
            else {
                toast('Somwthing worng!!')
            }
            getUserInfo().then((data) => {
                setUser(data.data)
                setUpdate(false);


            });

        })
    }
    // logout 
    const logout = () => {
        userLogout().then((data) => {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('user_info');
            history.push(`/`);
        });
    };
    //set user name
    useEffect(() => {
        let userInfo = JSON.parse(
            localStorage.getItem('user_info') || ''
        );
        if (userInfo.name) {
            setUserName(userInfo.name);
        }
    }, []);

    const handlePasswordChange = (evnt: any) => {
        setPasswordInput(evnt.target.value);
    }
    const passwordStrength = (evnt: any) => {
        const passwordValue = evnt.target.value;
        const passwordLength = passwordValue.length;
        const poorRegExp = /[a-z]/;
        const weakRegExp = /(?=.*?[0-9])/;;
        const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
        const whitespaceRegExp = /^$|\s+/;
        const poorPassword = poorRegExp.test(passwordValue);
        const weakPassword = weakRegExp.test(passwordValue);
        const strongPassword = strongRegExp.test(passwordValue);
        const whiteSpace = whitespaceRegExp.test(passwordValue);

        if (passwordValue === '') {
            setPasswordErr("Password is Empty");
        } else {

            // to check whitespace
            if (whiteSpace) {
                setPasswordErr("Whitespaces are not allowed");
            }
            // to check poor password
            if (passwordLength <= 3 && (poorPassword || weakPassword || strongPassword)) {
                setPasswordStrong(0);
                setPasswordErr("Password is Poor");
            }
            // to check weak password
            if (passwordLength >= 4 && poorPassword && (weakPassword || strongPassword)) {
                setPasswordStrong(1);
                setPasswordErr("Password is Weak");
            }

            // to check strong Password
            if (passwordLength >= 6 && (poorPassword && weakPassword) && strongPassword) {
                setPasswordStrong(2);
                setPasswordErr("Password is Strong");
            }
        }
    }

    const passHide = () => {
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    return (   
					<>
                        {/* <Navbar /> //NOTE EVG this may need to be restored */}
                        <Col md={12} className="mb-5">
                            <div className='am_box text-center'>
                                <h1>{userInfo ? userInfo.name : ""}</h1>
                                <h4>{userInfo ? userInfo.role_id === 2 ? "Admin" : "Worker" : ""}</h4>
                            </div>
                            <div className='mt-5 ml-5 userInfo'>
                                <h3>Overview: </h3>
                                <h4><FontAwesomeIcon icon={faEnvelope} /> <p>Email:  {userInfo ? userInfo.email : ""}</p> </h4>
                                <h4><FontAwesomeIcon icon={faEye} /> <span>New Password:
                                    <div className="btn-group dropend">
                                        <p className="passwordBtn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                                            data-bs-auto-close="outside"
                                        >
                                            Set New Password
                                        </p>
                                        <ul className="dropdown-menu">
                                            <div className='password'>

                                                <input type={passwordType} value={updatePassword} onChange={(e) => { setUpdatePassword(e.target.value); handlePasswordChange(e) }} onInput={passwordStrength} name="password" placeholder="Password" className="form-control" />
                                                {passwordStrong === 0 ? (<p style={{ backgroundColor: 'red' }}>{passwordError}</p>) : passwordStrong === 1 ? (<p style={{ backgroundColor: 'rgb(241 230 22)' }}>{passwordError}</p>) : <p style={{ backgroundColor: '#04AA6D' }}>{passwordError}</p>}


                                            </div>
                                            <div className='passwordBtn'>
                                                <button className='btn btn-info' onClick={passHide}> {passwordType === "password" ? 'Show' : 'Hide'}</button>
                                                <button className='btn btn-info' onClick={clickUpdatePassword} >Update</button>
                                                {/* <button className='btn btn-info'>close</button> */}
                                            </div>
                                        </ul>
                                    </div>
                                </span>
                                </h4>
                            </div>
                        </Col>


                        {userInfo ? userInfo.role_id === 2 ?
                            (<Col md={10} className="centerPosiion">
								<div className='row monday-status py-2'>
									<div className='col-sm-6'>
										<div className='row'>
											<div className='col monday-status-text'>Monday.com Synchronization Status</div>
											<div className='col'>
												<span onClick={() => {setMondayWarning(true)}} className={'monday-btn badge ' + (mondayStatus ? 'bg-success' : 'bg-danger')}>{mondayStatus ? 'Enabled' : 'Disabled'}</span>
											</div>
										</div>
									</div>
									<Popup className='monday-popup' open={modayWarning} closeOnDocumentClick onClose={closeMondayModal}>
										<div className='container'>
											<div className='row'><div className='col title'>Change Status Confirmation</div></div>
											<div className='row my-4 mx-2'>
												<div className='col'>{mondayStatus ? 'Disable' : 'Enable'} Monday.com synchronization?</div>
												<div className='col button-cont'>
											
														<button onClick={() => updateMonday()} className='btn btn-primary confirm-btn'>Confirm</button>
														<button onClick={() => setMondayWarning(false)} className='btn btn-danger cancel-btn'>Cancel</button>
											
											
													
													
												</div>
											</div>
										
										</div>
									</Popup>
								</div>
                                <div className=''>
                                    <h4 className='text-center'>Users</h4>
                                    <div className="table-responsive accountTable">

                                        <table className="table tableBox">
                                            <tbody>
                                                <tr><td scope="row">

                                                    {/* {<button className='btn btnLeft' onClick={() => { getData() }}>Export All Table Data</button>} */}


                                                    {
                                                        loading ? (
                                                            <div className="text-center">
                                                                <img src="../Spinner-1s-200px.gif" width={38} alt="" />
                                                            </div>
                                                        ) : null}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td><button className='btn addBtn' onClick={() => setAdd(o => !o)}>Add User</button></td></tr>
                                                {user ? user.map((u: any, i: number) => (<tr key={`user_${i}`}><td className='userInfo'><p><span>User Name:</span>{u.name}</p></td>
                                                    <td className='userInfo'><p><span>Role:</span>{u.role}</p></td>
                                                    <td className='userInfo'><p><span>Status:</span>{u.funnels === true ? "Has assigned funnel(s)" : "Has no assigned funnel(s)"}</p></td>
                                                    <td>
                                                        <button className='btn deleteBtn' onClick={() => { setDeleteUserId(u.id); setDeleteInfo(o => !o) }}>Delete User</button>
                                                        <button type="button" className="btn btnUpdate" onClick={() => setUpdateUserBaseInfo(u)}>Update User</button>
                                                    </td></tr>)) : null}
                                            </tbody>
                                        </table>
                                        <Popup open={add} closeOnDocumentClick onClose={closeModal}>
                                            <div className='addForm'>
                                                <h1 >Add Information</h1>
                                                <label htmlFor='user'>User</label>
                                                <input className='form-control' type="text" id="user" name="user" value={newUserName} onChange={(e) => setNewUserName(e.target.value)} required />
                                                <label htmlFor='email'>Email</label>
                                                <input className='form-control' type="email" id="email" name="email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} required />
                                                <label htmlFor='role'>Role</label>
                                                {/* <input className='form-control' type="text" id="role" name="role" value={newUserRole} onChange={(e) => setNewUserRole(e.target.value)} required /> */}
                                                <select defaultValue={'DEFAULT'} className="" onChange={(e) => setNewUserRole(e.target.value)} aria-label="Default select example">
                                                    <option value="DEFAULT">Select Role</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="worker">Worker</option>
                                                </select>
                                                <button className="btn btnRight" onClick={clickAddNewUser}>Save</button>
                                            </div>
                                        </Popup>
                                        <Popup open={update} closeOnDocumentClick onClose={closeModal}>
                                            <div className='addForm'>
                                                <h1 >Update Information</h1>
                                                <label htmlFor='user'>User</label>
                                                <input className='form-control' type="text" id="user" name="user" value={updateUserName} onChange={(e) => setUpdateUserName(e.target.value)} />

                                                <label htmlFor='status'>Email</label>
                                                <input className='form-control' type="text" id="email" name="email" value={updateUserEmail} onChange={(e) => setUpdateUserEmail(e.target.value)} />
                                                <label htmlFor='role'>Role</label>
                                                {/* <input className='form-control' type="text" id="role" name="role" value={updateUserRole} onChange={(e) => setUpdateUserRole(e.target.value)} /> */}

                                                <select defaultValue={'DEFAULT'} id="role" className="" onChange={(e) => setUpdateUserRole(e.target.value)} aria-label="Default select example">
                                                    <option value="DEFAULT" >Select</option>
                                                    <option value="admin">Admin</option>
                                                    <option value="worker">Worker</option>
                                                </select>

                                                <button className="btn btnRight" onClick={clickUpdateUser}>Save</button>
                                            </div>
                                        </Popup>
                                        <Popup open={deleteInfo} closeOnDocumentClick onClose={closeModal}>
                                            <div className='addForm'>
                                                <h1>Are you sure?</h1>
                                                <div className='deleteDorm'>
                                                    <button className="btn deleteRight" onClick={clickDeleteUser}>YES</button>
                                                    <button className="btn deleteLeft" onClick={closeModal}>NO</button>
                                                </div>
                                            </div>
                                        </Popup>
                                    </div>
                                </div>
                            </Col>) : "" : ""}

                        {userInfo ? userInfo.role_id === 2 ?
                            null
                            :
                            <Col md={12} className="centerPosiion">
                                <div className='userBtn ml-5'>
                                    {/* {<button className='btn btnLeft' onClick={() => { getData() }}>Export All Table Data {
                                        loading ? (
                                            <img src="../Spinner-1s-200px.gif" width={38} alt="" />
                                        ) : null}</button>} */}
                                </div>
                            </Col>
                            : ""}
                        <ToastContainer />
					</>



    )
}

export default AccountManagement
