import React,  { useEffect, useMemo, useState } from 'react';
import './OrderPlan.css';
import TableC from '../Component/Table/TableC';
import { TableColumn, TableDataRow, RowActionValues } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {
	faFolderPlus, faRefresh, faTrash, faPencil, faPlay, faBarChart
} from '@fortawesome/free-solid-svg-icons';
import dayjs, { Dayjs } from "dayjs";
import { Container, Row, Col, Stack, Button, Toast, ToastContainer  } from 'react-bootstrap';
import {
	createOrderPlan,
	getOrderPlanStatus,
	getPlans,
	updateOrderPlan,
	getExecuteOrderPlan,
	deleteOrderPlan,
	getReplacementPlanLink,
	deletePlanLink,
	deletePlanWebsite,
	rerunPlanDomain
  } from '../shared/services/api';
 import Plans from '../Component/Plans/Plans';
import DomainPlanEditor from '../Component/DomainPlanEditor/DomainPlanEditor';
import AnchorTextEditor from '../Component/AnchorTextEditor/AnchorTextEditor';

type URLParams = {
	id: string;
};

type OrderPlanData = {
	id: number;
	name: string;
	status: number;
}



const OrderPlan = () => {
	let { id } = useParams<URLParams>();
	const [planData, setPlanData] = useState<OrderPlanData | null>(null);
    const history = useHistory();
	
	const [showTable, setShowTable] = useState<boolean>(false);

	const [showNewPlanDialog, setShowNewPlanDialog] = useState<boolean>(false);
	const [openPlans, setOpenPlans] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	const [openDomainEditor, setOpenDomainEditor] = useState<boolean>(false);
	const [newOrderPlanName, setNewOrderPlanName] = useState<string>('Order Plan ' + dayjs().format('DD.MM.YYYY'));
	const [showConfirmRemoveDomian, setShowConfirmRemoveDomian] = useState<boolean>(false);
	const [showExecution, setShowExecution] = useState<boolean>(false);
	const [showConfirmDeleteOrderPlan, setShowConfirmDeleteOrderPlan] = useState<boolean>(false);
	// const [deleteOrderPlanId, setDeleteOrderPlanId] = useState<string>('');
	const [removeDomainId, setRemoveDomainId] = useState<number | null>();
	const [showRerunDomian, setShowRerunDomian] = useState<boolean>(false);
	const [editPlanDomainId, setEditPlanDomainId] = useState<number | null>(null);
	const [rerunPlanDomainId, setRerunPlanDomainId] = useState<number | null>(null);
	const [rerunPlanId, setRerunPlanId] = useState<number | null>(null);
	const [openAnchorEditor, setOpenAnchorEditor] = useState<boolean>(false);
	const [editAcnhorPlanDomainId, setEditAcnhorPlanDomainId] = useState<number | null>(null);
	const [editAcnhorCurrentText, setEditAcnhorCurrentText] = useState<string>('');
	const [showToastError, setShowToastError] = useState<boolean>(false);
	const [toastErrorText, setToastErrorText] = useState<string>('');

	const [plans, setPlans] = useState<any[]>([]);

	const planningColumns : TableColumn[] = [
		{
			label: 'Name',
			id: 'name',
			type: 'string',
			sort: SORT_TYPE.ASCE
		},
		{
			label: 'Funnel Name',
			id: 'funnel_name',
			type: 'string',
		},
		{
			label: 'Activation Status',
			id: 'activation_status',
			type: 'string',
		},
		{
			label: 'Website Goal',
			id: 'goal',
			type: 'string',
		},
		{
			label: 'Language',
			id: 'language',
			type: 'string',
		},
		{
			label: 'Type',
			id: 'type',
			type: 'string',
		},
		{
			label: 'Priority',
			id: 'priority',
			type: 'enum',
			map: [
				{
					label: 'LOW',
					value: 'low'
				},
				{
					label: 'MEDIUM',
					value: 'medium'
				},
				{
					label: 'MEDIUM+',
					value: 'medium_plus'
				},
				{
					label: 'HIGH',
					value: 'high'
				},
				{
					label: 'HIGHEST',
					value: 'highest'
				},
				{
					label: 'HIGHEST+',
					value: 'highest_plus'
				},
			]
		},
		{
			label: 'WebMaster',
			id: 'webmaster',
			type: 'user',
		},
		{
			label: 'Included',
			id: 'in_plan',
			type: 'checkbox',
			sortable: false
		},
		{
			label: 'Plan',
			id: 'plan_id',
			type: 'dropdown',
			map: plans.map((plan) => {return {label: plan.name, value: plan.id}}),
			tiedCheckboxColumn: 'in_plan',
			sortable: false
		},
	];	
	const executedColumns : TableColumn[] = [
		{
			label: 'URL',
			id: 'name',
			type: 'string',
			sort: SORT_TYPE.ASCE
		},
		{
			label: 'Anchor Text',
			id: 'anchor_text',
			type: 'string',
		},
		{
			label: 'Edit Text',
			id: 'edit_anchor_text',
			type: 'button',
			filterable: false,
			renderFunc: (row: TableDataRow) => {
				return <FontAwesomeIcon
							icon={faPencil}
							className="table-cell-icon"
						/>
			},
			buttonClass: 'order-plan-edit-anchor-btn',
			subType: 'general_action',
			buttonAction: function (row: TableDataRow): void {
				setEditAcnhorCurrentText(row.anchor_text as string);
				setEditAcnhorPlanDomainId(row.id as number);
				setOpenAnchorEditor(true);
			}
		},
		{
			label: 'Link Type',
			id: 'link_type',
			type: 'string',
		},
		{
			label: 'Link Domain',
			id: 'link_domain',
			type: 'string',
		},
		{
			label: 'DR',
			id: 'dr',
			type: 'number',
		},
		{
			label: 'Max Price',
			id: 'final_price',
			type: 'number',
		},
		{
			label: 'Currency',
			id: 'currency',
			type: 'string',
		},
		{
			label: 'Language',
			id: 'language',
			type: 'string',
		},
		{
			label: 'Niche',
			id: 'niche',
			type: 'string',
		},
		{
			label: 'Location',
			id: 'location',
			type: 'string',
		},
		{
			label: 'Funnel Name', //Always name from all_websites or Funnel Name when present
			id: 'funnel_name',
			type: 'string',
		},
		{
			label: 'Funnel Language',
			id: 'funnel_language',
			type: 'string',
		},
		{
			label: 'Priority',
			id: 'priority',
			type: 'enum',
			map: [
				{
					label: 'NOTHING',
					value: 'nothing'
				},
				{
					label: 'LOW',
					value: 'low'
				},
				{
					label: 'MEDIUM',
					value: 'medium'
				},
			{
					label: 'MEDIUM+',
					value: 'medium_plus'
				},
				{
					label: 'HIGH',
					value: 'high'
				},
				{
					label: 'HIGHEST',
					value: 'highest'
				},
				{
					label: 'HIGHEST+',
					value: 'highest_plus'
				},
			]
		},
		{
			label: 'WebMaster',
			id: 'webmaster',
			type: 'user',
		},
		{
			label: 'Replace',
			id: 'replace',
			type: 'button',
			filterable: false,
			renderFunc: (row: TableDataRow) => {
				return <FontAwesomeIcon
							icon={faRefresh}
							className="table-cell-icon"
						/>
			},
			buttonClass: 'order-plan-replace-btn',
			subType: 'row_action',
			buttonAction: async function (row: TableDataRow): Promise<TableDataRow> {
				return getReplacementPlanLink(row.id as number).then((res) => {
					if (res.error) {
						setToastErrorText(res.message);
						setShowToastError(true);
					}
					return res.row;
				})
			},
			sortable: false
		},
		{
			label: 'Remove',
			id: 'remove',
			type: 'button',
			filterable: false,
			renderFunc: (row: TableDataRow) => {
				return <FontAwesomeIcon
							icon={faTrash}
							className="table-cell-icon"
						/>
			},
			buttonClass: 'order-plan-delete-btn',
			subType: 'row_action',
			buttonAction: async function (row: TableDataRow): Promise<null> {
				return deletePlanLink(row.id as number).then((res) => {
					return null;
				})
			},
			sortable: false
		},
	];

	const closeNewPlanDialog = () => {
		setNewOrderPlanName('Order Plan ' + dayjs().format('DD.MM.YYYY'));
		setShowNewPlanDialog(false);
	}

	const closeRemoveDomain = () => {
		setRemoveDomainId(null);
		setShowConfirmRemoveDomian(false);
	}

	const closeExecution = () => {
		setShowExecution(false);
	}

	const openRemoveDomainConfirmation = (domainId: number) => {
		setRemoveDomainId(domainId);
		setShowConfirmRemoveDomian(true);
	}

	const deleteDomain = () => {
		deletePlanWebsite(parseInt(id) as number, removeDomainId as number).then(() => {
			closeRemoveDomain();
			refreshMainTable();
		})
	}

	const hideToastError = () => {
		setToastErrorText('');
		setShowToastError(false);
	}

	const closeRerunDomain = () => {
		setRerunPlanDomainId(null);
		setRerunPlanId(null);
		setShowRerunDomian(false);
	}

	const refreshMainTable = () => {
		console.log('refreshing..', id);
		//TODO chcek if there's a better way
		history.push('/boards/order_plan/', {
			refreshSidebar: true,
			//TODO FINISH this once folders are added
			// openFolderId: res.data.folder_id,
			// openFolderName: res.data.folder_name,
		});
		history.push('/boards/order_plan/' + id, {
			refreshSidebar: true,
			//TODO FINISH this once folders are added
			// openFolderId: res.data.folder_id,
			// openFolderName: res.data.folder_name,
		});
	}

	const fetchPlanData = () => {
		setShowTable(false);
		setLoading(true);
		getOrderPlanStatus(id).then((res) => {
			if (res.error) {
				setLoading(false);
				setToastErrorText(res.message);
				setShowToastError(true);
			} else {
				getPlans().then((plans) => {
					setPlans(plans.data);
					setPlanData(res.data);
					setLoading(false);
					setShowTable(true);
				})	
			}
		})
	}

	const executePlan = () => {
		if (id) {
			closeExecution();
			getExecuteOrderPlan(id).then(() => {
				fetchPlanData();
			})
		}
	}

	const openConfirmOrderPlanDeletion = () => {
		if (id) {
			setShowConfirmDeleteOrderPlan(true);
		}
	}

	const handleDeleteOrderPlan = () => {
		if (id) {
			setShowConfirmDeleteOrderPlan(false);
			deleteOrderPlan(id).then(() => {
				history.push('/boards/order_plan', {
					refreshSidebar: true,
					//TODO FINISH this once folders are added
					// openFolderId: res.data.folder_id,
					// openFolderName: res.data.folder_name,
				});
			})
		}
	}

	const closeDeleteOrderPlan = () => {
		setShowConfirmDeleteOrderPlan(false);
	}

	useEffect(() => {
		if (id) {
			fetchPlanData();
		}
	}, [id])

	const defaultPlanMap : Map<string, number> = useMemo(() => {
		const pMap = new Map<string, number>();
		for (const plan of plans) {
			if (plan.default) {
				pMap.set(plan.priority, plan.id);
			}
		}
		return pMap;
	}, [plans])
	

	const saveNewOrderPlan = () => {
		createOrderPlan(newOrderPlanName).then((res) => {
			if (res.error) {
				setToastErrorText(res.message);
				setShowToastError(true);
			} else {
				setShowNewPlanDialog(false);
				history.push('/boards/order_plan/' + res.data.created_order_plan_id, {
					refreshSidebar: true,
					openFolderId: res.data.folder_id,
					openFolderName: res.data.folder_name,
				});
			}
		})	
	}

	const saveOrderPlan = (data: Map<number, RowActionValues>) => {
		return updateOrderPlan(Array.from(data), parseInt(id));
	}

	const renderPlanTable = () => {
		if (planData?.status === 0 && showTable) {
			return <TableC id={id} type='order_plan' useFilters={true} editable={false} columns={planningColumns} allowUpload={false} saveCallback={saveOrderPlan} />
		} else if (planData?.status === 2 && showTable) {
			return <TableC id={id} type='order_plan' useFilters={true} editable={false} columns={executedColumns} allowUpload={false} useGroups={true} customGroupHeader={renderGroupHeader} />
		} else {
			return showError('Plan execution is in progress');
		} 
	}

	const onClosePlans = (refresh: boolean = false) => {
		setOpenPlans(false);
		if (refresh && id) {
			refreshMainTable();
		}
	}

	const onCloseDomainEditor = () => {
		setOpenDomainEditor(false);
		refreshMainTable();
	}

	const openManualEdit = (planDomainId: number) => {
		setEditPlanDomainId(planDomainId);
		setOpenDomainEditor(true);
	}

	const openRerun = (row : TableDataRow) => {
		setRerunPlanDomainId(row.id as number);
		let planId = row.plan_id ? row.plan_id : defaultPlanMap.get(row.priority as string);
		setRerunPlanId(planId as number);
		setShowRerunDomian(true);
	}

	const rerunDomain = () => {
		setLoading(true);
		rerunPlanDomain(rerunPlanDomainId as number, rerunPlanId as number).then(() => {
			setLoading(false);
			refreshMainTable();
		})
		closeRerunDomain();
	}

	const onCloseAnchorEditor = () => {
		setOpenAnchorEditor(false);
		refreshMainTable();
	}

	const renderGroupHeader = (row: TableDataRow) => {
		return <>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={3}><strong>{row.name}</strong></td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning tooltipp')} colSpan={2}>
				{row.status === 'success' ? 
					<span>Status: Success</span> : 
					<>
						<span>Warning: </span>
						{
							row.note ? 
								<>
									{(row.note as string).substring(0, 10) + '...'}
									<span className="tooltipptext">
										{row.note}
									</span>
								</> 
								: 
								null
						}
					</>
				}
			</td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={2}>Plan: {row.plan}</td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={2}>Total Links: {row.count}</td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={2}>Total Price: {row.total_price}</td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={2}>
				<Button variant='info' onClick={() => openRerun(row)}>
					<FontAwesomeIcon
						icon={faRefresh}
						className="user-icon"
					/>{' '}
					Rerun
				</Button>
			</td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={2}>
				<Button variant='warning' onClick={() => openManualEdit(row.id as number)}>
					<FontAwesomeIcon
						icon={faPencil}
						className="user-icon"
					/>{' '}
					Edit Links
				</Button>
			</td>
			<td className={'virt-table-cell group-header-cell order-plan-header' + (row.status === 'success' ? ' plan-success' : ' plan-warning')} colSpan={2}>
				<Button variant='danger' onClick={() => openRemoveDomainConfirmation(row.all_websites_id as number)}>
					<FontAwesomeIcon
						icon={faTrash}
						className="user-icon"
					/>{' '}
					Remove Domain
				</Button>
			</td>
		</>
	}

	const showError = (errorText: string) => {
		return (
			
			<div className="col-md-12 text-center mt-5">
				<h2 className="text-danger">{errorText}</h2>
			</div>

		);
	};
  return (
    <>	  
        <Stack direction="horizontal" gap={3} className='order-plan-button-cont py-2'>
			<button className="btn new-plan-btn" onClick={() => setShowNewPlanDialog(true)} >
				<FontAwesomeIcon
					icon={faFolderPlus}
					className="user-icon"
				/>{' '}
				New Order Plan
			</button>
			<button className="btn btn-primary plans-btn" onClick={() => setOpenPlans(true)} >
				<FontAwesomeIcon
					icon={faBarChart}
					className="user-icon"
				/>{' '}
				Plans
			</button>
			{
				(id && planData) ?
					<div className='ms-auto'>
					{
						(planData.status === 0) ? 
						<button className="btn btn-success execute-order-plan-btn" onClick={() => setShowExecution(true)} >
							<FontAwesomeIcon
								icon={faPlay}
								className="user-icon"
							/>{' '}
							Execute Plan
						</button>
						: null
					}
					{
						(planData.status !== 1) ? 
						<>
							<button className="btn btn-danger del-order-plan-btn mx-4" onClick={() => openConfirmOrderPlanDeletion()} >
								<FontAwesomeIcon
									icon={faTrash}
									className="user-icon"
								/>{' '}
								Delete Plan
							</button>
						</>
						
						: null
					}
					</div> : null
			}
		</Stack>
		<Plans open={openPlans} onClose={onClosePlans} />
		<Popup open={showNewPlanDialog} className="remove-domain-popup" closeOnDocumentClick onClose={() => closeNewPlanDialog()}>
			<div className="container">
				<div className='row mb-4'>
					<div className='col-sm-8 offset-sm-2 new-order-plan-title'>Create a new Order Plan</div>
					<div className='close-btn-cont col'>
						<button type="button" className="btn-close" aria-label="Close" onClick={() => closeNewPlanDialog()}></button>
					</div>
				</div>
				<div className='row mb-4'>
					<div className='col-sm-2'>Name: </div>
					<div className='col-sm-10'>
						<input type='text' value={newOrderPlanName} onChange={(e) => setNewOrderPlanName(e.target.value)} />
					</div>
				</div>
				<div className='row'>
					<div className='col remove-domain-btn-cont'>
						<button className="btn btn-success" onClick={saveNewOrderPlan}>Create</button>
					</div>
				</div>
			</div>
		</Popup>
		{
			id ?
				loading ? (
					<div className="col-md-12 text-center loadingImage " >
						<img src="/Spinner-1s-200px.gif" alt="" />
					</div>
				) 
				:
				planData ? 
				(<>
					<DomainPlanEditor open={openDomainEditor} onClose={onCloseDomainEditor} planDomainId={editPlanDomainId as number} />
					<AnchorTextEditor open={openAnchorEditor} onClose={onCloseAnchorEditor} planDomainId={editAcnhorPlanDomainId as  number} currentText={editAcnhorCurrentText} />
					<ToastContainer position='bottom-end' style={{ zIndex: 9999 }}>
					<Toast show={showToastError} onClose={hideToastError}>
						<Toast.Header>
							<strong className="me-auto" style={{color: 'red'}}>Error</strong>
						</Toast.Header>
						<Toast.Body>{toastErrorText}</Toast.Body>
					</Toast>
					</ToastContainer>
					{renderPlanTable()}
					<Popup open={showConfirmRemoveDomian} className="remove-domain-popup" closeOnDocumentClick onClose={() => closeRemoveDomain()}>
						<div className="container">
							<div className='row mb-4'>
								<div className='col remove-domain-popup-title'>Are you sure you want to remove this domain from the plan?</div>
							</div>
							<div className='row'>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-danger" onClick={deleteDomain}>Yes</button>
								</div>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-success" onClick={closeRemoveDomain}>No</button>
								</div>
							</div>
						</div>
					</Popup>
					<Popup open={showExecution} className="remove-domain-popup" closeOnDocumentClick onClose={() => closeExecution()}>
						<div className="container">
							<div className='row mb-2'>
								<div className='col remove-domain-popup-title'><strong>Begin plan Execution?</strong></div>
							</div>
							<div className='row mb-4'>
								<div className='col'>Make sure to save your changes beforehand using the save button!</div>
							</div>
							<div className='row'>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-danger" onClick={executePlan}>Yes</button>
								</div>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-success" onClick={closeExecution}>No</button>
								</div>
							</div>
						</div>
					</Popup>
					<Popup open={showConfirmDeleteOrderPlan} className="remove-domain-popup" closeOnDocumentClick onClose={() => closeDeleteOrderPlan()}>
						<div className="container">
							<div className='row mb-4'>
								<div className='col remove-domain-popup-title'>Are you sure you want to delete this Order Plan?</div>
							</div>
							<div className='row'>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-danger" onClick={handleDeleteOrderPlan}>Yes</button>
								</div>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-success" onClick={closeDeleteOrderPlan}>No</button>
								</div>
							</div>
						</div>
					</Popup>
					<Popup open={showRerunDomian} className="remove-domain-popup" closeOnDocumentClick onClose={() => closeRerunDomain()}>
						<div className="container">
							<div className='row mb-4'>
								<div className='col remove-domain-popup-title'>Rerun this domain?</div>
							</div>
							<div className='row mb-4'>
								<div className='col'>
									<div className='row'>
										<div className='col-sm-6'>Select Plan:</div>
										<div className='col-sm-6'>
											<select
												value={rerunPlanId as number}
												onChange={(e) => setRerunPlanId(parseInt(e.target.value))}
											>
												{plans.map((opt) => <option value={opt.id}>{opt.name}</option>)}
											</select>
										</div>
									</div>
						
								</div>
							</div>
							<div className='row'>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-danger" onClick={rerunDomain}>Yes</button>
								</div>
								<div className='col remove-domain-btn-cont'>
									<button className="btn btn-success" onClick={closeRerunDomain}>No</button>
								</div>
							</div>
						</div>
					</Popup>
				</>)
				
				: showError('Invalid Order Plan ID')
				:
				showError('Please select or create an Order Plan')
		}
    </>
  );
};

export default OrderPlan;
