import React from 'react';
import TableC from '../Component/Table/TableC';
import { TableColumn } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';

const columns : TableColumn[] = [
	{
		label: 'Name',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
		label: 'DR',
		id: 'dr',
		type: 'number',
	},
	{
		label: 'RD',
		id: 'rd',
		type: 'number',
	},
	{
		label: 'LD',
		id: 'ld',
		type: 'number',
	},
	{
		label: 'Traffic Since',
		id: 'traffic_since',
		type: 'number',
	},
	{
		label: 'Traffic',
		id: 'traffic',
		type: 'number',
	},
	{
		label: 'Old Traffic',
		id: 'old_traffic',
		type: 'number',
	},
	{
		label: 'Traffic Difference',
		id: 'traffic_difference',
		type: 'number',
		subType: 'percent',
	},
	{
		label: 'Niche',
		id: 'niche',
		type: 'string',
	},
	{
		label: 'Countries',
		id: 'countries',
		type: 'string',
	},
	{
		label: 'Final Price',
		id: 'final_price',
		type: 'number',
	},
	{
		label: 'Location',
		id: 'location',
		type: 'string',
	},
	{
		label: 'Language',
		id: 'language',
		type: 'string',
	},
	{
		label: 'Last Updated',
		id: 'updated_at',
		type: 'date',
	},
	{
		label: 'Contact Info',
		id: 'contact_info',
		type: 'string',
	},
	{
		label: 'Continuous/Discontinued',
		id: 'continuous',
		type: 'string'
	},
	{
		label: 'Type',
		id: 'type',
		type: 'string',
	},
	{
		label: 'Currency',
		id: 'currency',
		type: 'string',
	},
	{
		label: 'Bulk Orders',
		id: 'bulk_orders',
		type: 'string',
	}
];	

const Stash = () => {
  
  return (
    <>	  
        {<TableC id={'stash'} type='stash' useFilters={true} editable={true} columns={columns} allowUpload={true} />}
    </>
  );
};

export default Stash;
