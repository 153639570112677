import { FormEvent, useState } from 'react';
import './AnchorBank.css';
import TableC from '../Component/Table/TableC';
import { TableColumn, TableDataRow } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faFolderPlus
} from '@fortawesome/free-solid-svg-icons';
import {
	deleteAnchorText,
    createAnchorText
  } from '../shared/services/api';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const columns : TableColumn[] = [
	{
		label: 'Text',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
        label: 'Type',
        id: 'type',
        type: 'enum',
        map: [
            {
                label: 'URLs',
                value: 'urls'
            },
            {
                label: 'Funnel Name',
                value: 'funnel_name'
            },
            {
                label: 'Natural',
                value: 'natural'
            }
        ]
    },
    {
        label: 'Remove',
        id: 'remove',
        type: 'button',
        renderFunc: (row: TableDataRow) => {
            return <FontAwesomeIcon
                        icon={faTrash}
                        className="table-cell-icon"
                    />
        },
        buttonClass: 'btn-danger',
        subType: 'row_action',
        buttonAction: async function (row: TableDataRow): Promise<null> {
            return deleteAnchorText(row.id as number).then((res) => {
                return null;
            })
        }
    },
];	

const AnchorBank = () => {
    const [showNewAnchorDialog, setShowNewAnchorDialog] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [validText, setValidText] = useState<boolean>(true);
    const [textError, setTextError] = useState<string>('Please provide an Anchor Text');
    const history = useHistory();

    const closeNewAnchorDialog = () => {
        setShowValidation(false);
        resetError();
        setShowNewAnchorDialog(false);
    }

    const handleSave = (e: FormEvent) => {
        let form = e.currentTarget as HTMLFormElement;
        e.preventDefault();
        e.stopPropagation();
        const formData = Object.fromEntries((new FormData(form)).entries());
        if (!formData.text) {
            setValidText(false);
        } else {
            setValidText(true);
        }
        if (form.checkValidity() === false) {
            // setValidNewAnchor(false);
        } else {
            const formData = Object.fromEntries((new FormData(form)).entries());
            createAnchorText(formData.text as string, formData.type as string).then((res) => {
                if (res.error) {
                    setTextError(res.message);
                    setValidText(false);
                } else {
                    closeNewAnchorDialog();
                    history.push('/boards/anchor_bank/a', {
                        refreshSidebar: true,
                    });
                    history.push('/boards/anchor_bank', {
                        refreshSidebar: true,
                    });
                }
            })
        }
        setShowValidation(true);
    }

    const resetError = () => {
        setValidText(true);
        setTextError('Please provide an Anchor Text');
    }

    return (
        <>	  
            <Row>
                <Col sm={2}>
                    <Button variant='success' className='new-anchor-btn' onClick={() => setShowNewAnchorDialog(true)}>
                        <FontAwesomeIcon
							icon={faFolderPlus}
							className="user-icon"
						/>{' '}
						New Anchor Text
                    </Button>
                </Col>
            </Row>
            <Modal 
                show={showNewAnchorDialog} 
                closeOnDocumentClick onHide={() => closeNewAnchorDialog()}  
                dialogClassName='new-anchor-popup' 
                animation={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Anchor Text
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={handleSave} validated={showValidation} >
                            <Row>
                                <Form.Group as={Col} sm="8" controlId='anchorTextValidation'>
                                    <Form.Label>Anchor Text</Form.Label>
                                    <Form.Control required type='text' name='text' isInvalid={!validText} onChange={(e) => resetError()} />
                                    <Form.Control.Feedback type="invalid">
                                        {textError}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm="4" controlId='anchorTypeValidation'>
                                    <Form.Label>Anchor Type</Form.Label>
                                    <Form.Select name='type'>
                                        {columns[1].map?.map((opt) => (
                                            <option value={opt.value}>{opt.label}</option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <Row className='justify-content-center mt-4'>
                                <Button className='col-sm-2' variant='success' type='submit'>Save</Button>     
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>
            
            {<TableC id={'anchor_bank'} type='anchor_bank' useFilters={false} editable={true} columns={columns} allowUpload={true} />}
        </>
      );
}

export default AnchorBank;