import { FormEvent, useEffect, useState } from 'react';
import './AnchorTextEditor.css';
import { TableDataResponse, TableDataRow } from '../../Component/Table/interface'; 
import { getAnchorBank, updatePlanDomainAnchor } from "../../shared/services/api";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import Select from 'react-select';

type AnchorTextEditorProps = {
    open: boolean,
    onClose: () => void,
    planDomainId: number,
    currentText: string
}

type AnchorTextMap = Map<string, Array<{value: number, label: string}>>


const AnchorTextEditor = ({open, onClose, planDomainId, currentText}: AnchorTextEditorProps) => {
    const [selectedAnchorType, setSelectedAnchorType] = useState<string>('free_text');
    const [allAnchorData, setAllAnchorData] = useState<AnchorTextMap>(new Map());
    
    const AnchorTypes = [
        {
            label: 'Free Text',
            id: 'free_text'
        },
        {
            label: 'Natural',
            id: 'natural'
        },
        {
            label: 'URLs',
            id: 'urls'
        },
        {
            label: 'Funnel Name',
            id: 'funnel_name'
        },
    ]
    
    const processBank = (bankData: TableDataResponse) => {
        const processedAnchors: AnchorTextMap = new Map();
        for (const row of bankData.rows as TableDataRow[]) {
            if (!processedAnchors.has(row.type as string)) {
                processedAnchors.set(row.type as string, []);
            }
            let typeAnchors = processedAnchors.get(row.type as string);
            typeAnchors?.push({
                value: row.id as number,
                label: row.name
            })
        }
        setAllAnchorData(processedAnchors);
    }
    
    useEffect(() => {
        if (open && planDomainId) {
            getAnchorBank().then((res) => {
                processBank(res.data);
            })
        }
    }, [open, planDomainId])

    const handleAnchorTypeChange = (type: string) => {
        setSelectedAnchorType(type)
    }

    const handleSave = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        
        let form = e.currentTarget as HTMLFormElement;
        const formData = Object.fromEntries((new FormData(form)).entries());
        let newEntry = Object.entries(formData)[0];

        updatePlanDomainAnchor({type: newEntry[0], value: newEntry[1] as string | number}, planDomainId).then((res) => {
            setSelectedAnchorType('free_text');
            onClose();
        })
    }

    const handleClose = () => {
        setSelectedAnchorType('free_text');
        onClose();
    }

    return (
        <Modal 
            show={open} 
            closeOnDocumentClick onHide={() => handleClose()}  
            dialogClassName='anchor-editor-modal' 
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Anchor Text
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSave}>
                    {AnchorTypes.map((type) => (
                        <Row className='align-items-center mb-2'>
                            <Col sm={3}>
                                <Form.Check 
                                    type={'radio'}
                                    id={`anchor-${type.id}`}
                                    label={type.label}
                                    checked={type.id === selectedAnchorType}
                                    value={type.id}
                                    onChange={(e) => handleAnchorTypeChange(e.target.value)}
                                />
                            </Col>
                            <Col sm={9}>
                                {type.id === 'free_text' ? 
                                    <Form.Control defaultValue={currentText} disabled={type.id !== selectedAnchorType}  name='free_text' />
                                : 
                                <Select
                                    name={type.id}
                                    options={allAnchorData.get(type.id)}
                                    isMulti={false}
                                    isDisabled={type.id !== selectedAnchorType}
                                />                               
                                 }
                            </Col>
                    </Row>
                    ))}
                    <Row className='justify-content-center mt-4'>
                        <Button className='col-sm-2' variant='success' type='submit'>Save</Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default AnchorTextEditor;