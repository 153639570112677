import React from 'react';
import TableC from '../Component/Table/TableC';
import { TableColumn } from '../Component/Table/interface';
import { useParams } from 'react-router-dom';
import { SORT_TYPE } from '../Component/Table/enum';

type URLParams = {
	id: string;
};

const columns : TableColumn[] = [
	{
		label: 'Name',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
		label: 'DR',
		id: 'dr',
		type: 'number',
	},
	{
		label: 'RD',
		id: 'rd',
		type: 'number',
	},
	{
		label: 'LD',
		id: 'ld',
		type: 'number',
	},
	{
		label: 'Traffic Since',
		id: 'traffic_since',
		type: 'number',
	},
	{
		label: 'Traffic',
		id: 'traffic',
		type: 'number',
	},
	{
		label: 'Old Traffic',
		id: 'old_traffic',
		type: 'number',
	},
	{
		label: 'Traffic Difference',
		id: 'traffic_difference',
		type: 'number',
		subType: 'percent',
	},
	{
		label: 'Niche',
		id: 'niche',
		type: 'string',
	},
	{
		label: 'Countries',
		id: 'countries',
		type: 'string',
	},
	{
		label: 'Final Price',
		id: 'final_price',
		type: 'number',
	},
	{
		label: 'Location',
		id: 'location',
		type: 'string',
	},
	{
		label: 'Language',
		id: 'language',
		type: 'string',
	},
	{
		label: 'Last Updated',
		id: 'updated_at',
		type: 'date',
	},
	{
		label: 'Contact Info',
		id: 'contact_info',
		type: 'string',
	},
	{
		label: 'Continuous/Discontinued',
		id: 'continuous',
		type: 'string'
	},
	{
		label: 'Type',
		id: 'type',
		type: 'string',
	},
	{
		label: 'Currency',
		id: 'currency',
		type: 'string',
	},
	{
		label: 'Bulk Orders',
		id: 'bulk_orders',
		type: 'string',
	},
	{
		label: 'Last 3 Months',
		id: 'last_3_months',
		type: 'string',
	},
	{
		label: 'Last 3 Months Deals',
		id: 'last_3_months_deals',
		type: 'number',
	},
	{
		label: 'Total',
		id: 'total',
		type: 'number',
	}
];	

const LinksAvailability = () => {
	//Show error msg
	const showError = () => {
		return (
			
			<div className="col-md-12 text-center mt-5">
				<h2 className="text-danger">Please Select A Sub Menu</h2>
			</div>

		);
	};
	let { id } = useParams<URLParams>();
	
	return (
	<>
		{id ? <TableC type='links_availability' id={id} useFilters={true} useGroups={false} columns={columns} /> : showError()}
	</>
	);
};

export default LinksAvailability;
