import React from "react";

//gloabl css
import "./App.css";
import 'reactjs-popup/dist/index.css';

import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return <Routes />;
}

export default App;
