import React, { useState }  from 'react';
import './OrderFinal.css';
import TableC from '../Component/Table/TableC';
import { TableColumn } from '../Component/Table/interface';
import { SORT_TYPE } from '../Component/Table/enum';
import { useParams } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {
	faFolderPlus
} from '@fortawesome/free-solid-svg-icons';
import {
	createOrderFinalBoard
  } from '../shared/services/api';

type URLParams = {
	id: string;
};
const columns : TableColumn[] = [
	{
		label: 'Name',
		id: 'name',
		type: 'string',
		sort: SORT_TYPE.ASCE
	},
	{
		label: 'Anchor Text',
		id: 'anchor_text',
		type: 'string',
	},
	{
		label: 'Link Type',
		id: 'link_type',
		type: 'string',
	},
	{
		label: 'Linked Domain',
		id: 'linked_domain',
		type: 'string',
	},
	{
		label: 'DR',
		id: 'dr',
		type: 'number',
	},
	{
		label: 'Final Price',
		id: 'final_price',
		type: 'number',
	},
	{
		label: 'Link Status',
		id: 'link_status',
		type: 'string',
	},
	{
		label: 'Linked Domain LNG',
		id: 'linked_domain_lng',
		type: 'string',
	},
	{
		label: 'Funnel LNG',
		id: 'funnel_lng',
		type: 'string',
	},
	{
		label: 'Funnel Name',
		id: 'funnel_name',
		type: 'string',
	},
	{
		label: 'Niche',
		id: 'niche',
		type: 'string',
	},
	{
		label: 'WebMaster',
		id: 'webmaster',
		type: 'user',
		editable: false
	},
	{
		label: 'Link URL',
		id: 'link_url',
		type: 'string',
	},
	{
		label: 'Date Posted',
		id: 'created_at',
		type: 'date',
	},
	{
		label: 'Last Updated',
		id: 'updated_at',
		type: 'date',
	},
	{
		label: 'Status',
		id: 'status',
		type: 'string',
	}
];	

const OrderFinal = () => {
	const history = useHistory();
	const [showNewBoard, setShowNewBoard] = useState<boolean>(false);
	const [newBoardDate, setNewBoardDate] = useState<string>('');
	const [createError, setCreateError] = useState<string>('');

	const createNewBoard = (value: string) => {
		setNewBoardDate(value);
	}
	const closeNewBoard = () => {
		setNewBoardDate('');
		setCreateError('');
		setShowNewBoard(false);
	}

	const saveNewBoard = () => {
		createOrderFinalBoard(newBoardDate).then((res) => {
			if (res.error) {
				setCreateError(res.message)
			} else {
				setShowNewBoard(false);
				history.push('/boards/order_final/' + res.data.created_board_id, {
					refreshSidebar: true,
					openFolderId: res.data.folder_id,
					openFolderName: res.data.folder_name,
				});
			}
		})	
	}

	const showError = () => {
		return (
			
			<div className="col-md-12 text-center mt-5">
				<h2 className="text-danger">Please Select A Sub Menu</h2>
			</div>

		);
	};
	let { id } = useParams<URLParams>();
	
  return (
    <>	
		<div className='col'>
			<div className='row pt-2 pb-2 new-board-btn-cont'>
				<div className='col-md-2 leftElement'>
					<button className="btn new-board-btn" onClick={() => setShowNewBoard(true)} >
						<FontAwesomeIcon
							icon={faFolderPlus}
							className="user-icon"
						/>{' '}
						New Order Final Board
					</button>
				</div>
			</div>
			<Popup open={showNewBoard} className="new-board-popup" closeOnDocumentClick onClose={() => closeNewBoard()}>
				<div className="new-board container">
					<div className='row mb-4'>
						<div className='col'></div>
						<div className='col-sm-8 new-bord-title'>Create a new board</div>
						<div className='close-btn-cont col'>
							<button type="button" className="btn-close" aria-label="Close" onClick={() => closeNewBoard()}></button>
						</div>
					</div>
					<div className="row mb-4">
						<div className="col">Select month</div>
						<div className='col'>
							<input 
								className='newFolderInput'
								onChange={(e) => createNewBoard(e.target.value)}
								type={'month'} 
								value={newBoardDate} 
							/>
						</div>
					</div>
					{createError ? 
						<div className='row mb-3'>
							<div className='col create-error'>{createError}</div>
						</div> : ''}
					<div className='row'>
						<div className='col save-btn-cont'>
							<button className="btn save-board-btn" onClick={saveNewBoard}>Save</button>
						</div>
					</div>
				</div>
	  		</Popup> 
		</div>
	
		
        {id ? <TableC id={id} type='order_final' useFilters={true} editable={true} columns={columns} allowUpload={true} /> : showError()}
    </>
  );
};

export default OrderFinal;
