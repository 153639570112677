import { faCircleUser, faListCheck,  faPowerOff} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {  getStateInfo, startSynchronization, userLogout, getMondayStatus } from '../../shared/services/api';
import './Navbar.css';
import Popup from 'reactjs-popup';
import dayjs, { Dayjs } from "dayjs";

const Navbar = () => {
  const [userInfo, setUserInfo] = useState<any>();
  const [stateDate, setStateDate] = useState<Dayjs | null>(null);
  const [userName, setUserName] = useState<string>('');
  const history = useHistory();
  const [stateInfo, setStateInfo] = useState<any>({
    id: null,
    sync_all_websites: null,
    updated_at: null,
    sync_links_availability: null,
    sync_month_order_final: null
  });
  const [syncStatus, setSyncStatus] = useState<boolean>(true);
  const currDay = dayjs();

  // User role
  useEffect(() => {
    let user = localStorage.getItem('user_info')
    if (user) {
	  let userData = JSON.parse(user);
      setUserInfo(userData);
	  if (userData && userData.role_id === 2) {
		getMondayStatus().then((res) => {
			setSyncStatus(res.data);
		})
	  }
    }
  }, []);
  //set user name
  useEffect(() => {
    let userInfo = JSON.parse(
      localStorage.getItem('user_info') || ''
    );
    if (userInfo.name) {
      setUserName(userInfo.name);
    }
  }, []);

  //status popup
  const [showStatus, setShowStatus] = useState(false);
  const closeModal = () => {
    setShowStatus(false);
  };


  // on click api call for synchronization
  const onClickBtn = () => {
    startSynchronization().then((data) => {
    });
  };

  //get state data 
  const openModal = () => {
    getStateInfo().then(data => {
      setShowStatus(true)
      setStateInfo(data.data[0])

      const stateDate = dayjs(data.data[0].updated_at);
      setStateDate(stateDate);

    })
  }

  //logout
  const logout = () => {
    userLogout().then((data) => {
      localStorage.removeItem('auth_token');
      localStorage.removeItem('user_info');
      history.push(`/`);
    });
  };

  const renderSyncStatus = (status: number) => {
	const messages = ['Finished', 'In progress', 'Pending'];
	const classes = ['bg-success', 'bg-warning', 'bg-info'];
	return (
		<span className={'badge ' + classes[status]}>{messages[status]}</span>
	)
  }

  return (
    <>
      <div className="col-md-8 leftElement">
        <ul>
          {userInfo ? userInfo.role_id === 2 ? (<>
		  
		  
			<li>
				<button
					onClick={() => onClickBtn()}
					className="btn"
					id="synchronization"
				>
					{syncStatus ? 'Synchronization' : 'Update Links Availability'}
				</button>
			</li>
			<li className="nav-item popModal">
				<button className='btn btn-success' onClick={() => openModal()} >
					<FontAwesomeIcon
					icon={faListCheck}
					className="user-icon"
					/>
				</button>
				<Popup className='state-popup' open={showStatus} closeOnDocumentClick onClose={closeModal}>
					<div className='addForm stateBox'>
					<h1>State Information</h1>

					<h1>{stateInfo.in_sync === 0 ? <span className="text-success">System update completed</span> : <span className="text-warning">System update in progress</span>} | <span className={'dateText'}>Last update: <span className={currDay.diff(stateDate, 'day') > 1 ? 'text-danger' : 'text-success'}>{stateDate?.format('MMMM DD YYYY, HH:mm:ss')}</span></span></h1>

					<div className='state-div'>
						{syncStatus ? 
							<>
								<div className='d-flex justify-content-between'>
								<p>Sync All Websites:</p>
								<span>
									{renderSyncStatus(stateInfo.sync_all_websites)}
								</span>
								</div>

								<div className='d-flex justify-content-between'>
								<p>Sync Month Order Final:  </p>
								<span>
									{renderSyncStatus(stateInfo.sync_month_order_final)} 
								</span>
								</div>
							</> 
						: 
						''}
						
						<div className='d-flex justify-content-between'>
						<p>Calculate Links Availability:   </p>
						<span>
							{renderSyncStatus(stateInfo.sync_links_availability)}
						</span>
						</div>

					</div>
					<div className='deleteDorm'>
						<button className="btn deleteRight" onClick={closeModal}>Close</button>
					</div>
					</div>
				</Popup>
			</li>
			</>
          ) : "" : ""}

        </ul>
      </div>

      <div className="col-md-4 rightElement">
        <ul>
          <li className="logoutBtn">
            <Link to="/" className="" onClick={() => logout()}>
              <FontAwesomeIcon
                icon={faPowerOff}
                className="logout-icon"
              />
            </Link>
          </li>
          <li>
            <p className="user-name">
              <FontAwesomeIcon
                icon={faCircleUser}
                className="user-icon"
              />{' '}
              {userName}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
