import React, { useEffect, useState }from 'react';
import {
	TableColumns,
	TableDataRow,
	userMap
  } from './interface';
import Popup from 'reactjs-popup';
import {
	updateTableRow
} from '../../shared/services/api';

interface EditModalProps {
	dataRow: TableDataRow|null,
	columns: TableColumns,
	onEditRow: () => Promise<void>;
	type: string,
	userList: userMap
}

const RowEditor = ({dataRow, columns, onEditRow, type, userList}: EditModalProps) => {
	const [selectedRow, setSelectedRow] = useState<TableDataRow|null>(null);
	const [showEdit, setShowEdit] = useState<boolean>(false);

	useEffect(() => {
		if (dataRow) {
			editRow(dataRow as TableDataRow, 0);
		} else {
			setShowEdit(false);
		}
		
	}, [dataRow])
	
	const editRow = (row: TableDataRow, idx: number) => {
		let editRowData = {...row};
		for (const prop in editRowData) {
			if (columns.hasOwnProperty(prop) && columns[prop].type === 'date' && editRowData[prop]) {
				editRowData[prop] = (editRowData[prop] as string).substring(0,19);
			}
		}
		setSelectedRow(editRowData);
		setShowEdit(true);
	}
	
	  const updateEditedRow = (col: string, value: string | number | null) => {
		setSelectedRow({
			...selectedRow,
			[col]: value
		} as TableDataRow);
	  }
	
	  const closeEdit = () => {
		setSelectedRow(null);
		setShowEdit(false);
	  };
	
	  const updateRow = async () => {
		updateTableRow(selectedRow as TableDataRow, type, (selectedRow as TableDataRow).id as number).then((res) => {
			setShowEdit(false);
			onEditRow()
		})
	  }

	  const columnInputType = (type: string) => {
		if (type === 'string') {
			return 'text';
		} else if (type === 'date') {
			return 'datetime-local';
		} else {
			return type;
		}
	  }
	return (
		<Popup className='edit-popup' open={showEdit} closeOnDocumentClick onClose={closeEdit}>
			{selectedRow ? (
			<div className="editForm container">
				<div className='row justify-content-end'>
					<div className='close-btn-cont col-sm-1'>
						<button type="button" className="btn-close" aria-label="Close" onClick={() => setShowEdit(false)}></button>
					</div>
				</div>
				<div className='editRowHeading'>Edit row</div>
				{
					Object.values(columns).map((colData, i) => {
						return (
							<div className="row mb-2" key={`edit_row_${i}`}>
								{['dropdown', 'checkbox', 'button'].includes(colData.type) ? <></> :
								<div className="col">{colData.label}</div>}
								<div className="col-md">
									{colData.id === 'link_type' ? 
									<select 
										defaultValue={'DoFollow'} 
										className="rowEditInput" 
										value={(selectedRow as TableDataRow)[colData.id] ?? ''}  
										onChange={(e) => updateEditedRow(colData.id, e.target.value)}
										disabled={colData.hasOwnProperty('editable') ? !colData.editable : false} 
									>
										<option value={''}>Blank</option>
										<option value="DoFollow">DoFollow</option>
										<option value="NoFollow">NoFollow</option>
										<option value="Tier2">Tier2</option>
										<option value="Tier3">Tier3</option>
										<option value="Tier4">Tier4</option>
										<option value="Review Article">Review Article</option>
										<option value="301 Link">301 Link</option>
									</select>
									: 
									colData.id === 'link_status' ?
										<select 
											className="rowEditInput" 
											value={(selectedRow as TableDataRow)[colData.id] ?? ''}  
											onChange={(e) => updateEditedRow(colData.id, e.target.value)}
											disabled={colData.hasOwnProperty('editable') ? !colData.editable : false} 
										>
											<option value={''}>Blank</option>
											<option value="Sent for Posting">Sent for Posting</option>
											<option value="Published">Published</option>
											<option value="Replaced">Replaced</option>
											<option value="Approved">Approved</option>
											<option value="Replacement Required">Replacement Required</option>
											<option value="Start Now">Start Now</option>
											<option value="Price Change">Price Change</option>
											<option value="Need to Review">Need to Review</option>
											<option value="Need to Replace">Need to Replace</option>
											<option value="Sent Email">Sent Email</option>
											<option value="Negotiating">Negotiating</option>
											<option value="Submitted for Approval">Submitted for Approval</option>
											<option value="Start Now">Start Now</option>
											<option value="Published - Need to Fix">Published - Need to Fix</option>
											<option value="Remove Row">Remove Row</option>
											<option value="Scheduled">Scheduled</option>
											<option value="Hold">Hold</option>
										</select>
									:
									colData.id === 'status' ?
										<select 
											className="rowEditInput" 
											value={(selectedRow as TableDataRow)[colData.id] ?? ''}  
											onChange={(e) => updateEditedRow(colData.id, e.target.value)}
											disabled={colData.hasOwnProperty('editable') ? !colData.editable : false} 
										>
											<option value={''}>Blank</option>
											<option value="Published">Published</option>
											<option value="Start Now">Start Now</option>
										</select>
									:
									colData.type === 'enum' ?
										<select 
											className="rowEditInput" 
											value={(selectedRow as TableDataRow)[colData.id] ?? ''}  
											onChange={(e) => updateEditedRow(colData.id, e.target.value)}
											disabled={colData.hasOwnProperty('editable') ? !colData.editable : false} 
										>
											{colData.map?.map((item) => 
												<option value={item.value}>{item.label}</option>
											)}
										</select>
									:
									colData.id === 'webmaster' ?
									<select 
										className="rowEditInput" 
										value={(selectedRow as TableDataRow)[colData.id] ?? ''}  
										onChange={(e) => updateEditedRow(colData.id, e.target.value)}
										disabled={colData.hasOwnProperty('editable') ? !colData.editable : false} 
									>
										{
											Object.entries(userList).map(([userId, userName]) => {
												return <option key={`useropt_${userId}`} value={userId}>{userName}</option>
											})
										}
									</select>
									:
									['dropdown', 'checkbox', 'button'].includes(colData.type) ?
										<></>
									:
									<input 
										disabled={colData.hasOwnProperty('editable') ? !colData.editable : false} 
										className='rowEditInput'
										onChange={(e) => updateEditedRow(colData.id, e.target.value)}
										type={columnInputType(colData.type)} 
										value={(selectedRow as TableDataRow)[colData.id] ?? ''} 
									/>}
									
								</div>
							</div>
						)
					})
				}
				<div className='row justify-content-end'>
					<div className='col'>
					<button className="btn updateBtn" onClick={updateRow}>Update</button>
					</div>
				</div>
			</div>) : ''}
		</Popup>
	)
}


export default RowEditor;