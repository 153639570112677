import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isAuthenticate } from './shared/services/api';
import Sidebar from './Component/Sidebar/Sidebar';
import Navbar from './Component/Navbar/Navbar';
import { Container, Row } from 'react-bootstrap';

function AdminRoutes(props: RouteProps) {
  if (isAuthenticate()) {
    return (
		<div className="app">
				<Sidebar />
				<div className='main'>
					<Container className={''}>
						<Row>
							<Navbar />
							<Route path={props.path} exact component={props.component} />
						</Row>
					</Container>
				</div>
		</div>					
	)
  } else {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }
}

export default AdminRoutes;
