import React from 'react';
interface SearchProps {
    tableSearchTextTemp: string;
    setTableSearchTextTemp: (value: string) => void;
}
const SearchData = ({ tableSearchTextTemp, setTableSearchTextTemp }: SearchProps) => {
    return (
        <>
            <input
                type="search"
                value={tableSearchTextTemp}
                onChange={(e) => {
                    setTableSearchTextTemp(e.target.value);
                }}

                placeholder="Search"
            />
        </>
    )
}

export default SearchData