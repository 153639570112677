import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	getStash,
	getAllWebsites,
	getSingleBoard,
	getAnchorBank
  } from '../services/api';
import { TableDataResponse } from '../../Component/Table/interface'; 
import { ErrorResponse } from '../../Interface/SignInUser';

export function useFetchData() {
	const history = useHistory();
	const [data, setData] = useState<TableDataResponse>({
		board_name: '',
		rows: []
	})
	const doFetch = async (type: string, id: number | string, filters: any[]) => {
		let serverResponse ;
		if (type === 'stash') {
			serverResponse = await getStash(filters);
		}
		if (type === 'all_websites') {
			serverResponse = await getAllWebsites(filters);
		}
		if (type === 'anchor_bank') {
			serverResponse = await getAnchorBank();
		}
		if (type === 'links_availability') {
			serverResponse = await getSingleBoard(id as string, 'links_availability', filters);
		}
		if (type === 'order_final') {
			serverResponse = await getSingleBoard(id as string, 'order_final', filters);
		}
		if (type === 'order_plan') {
			serverResponse = await getSingleBoard(id as string, 'order_plan', filters);
		}
		if (type === 'domain_edit') {
			serverResponse = await getSingleBoard(id as string, 'domain_edit', filters);
		}
		if (type === 'historical') {
			serverResponse = await getSingleBoard(id as string, 'historical', []);
		}
		if ((serverResponse as ErrorResponse).error && (serverResponse as ErrorResponse).error === 'Invalid authorization') {
			localStorage.removeItem('user_info');
			localStorage.removeItem('auth_token');
			history.push(`/`);
		} else {
			setData(serverResponse.data);
		}
	}
	return {
		data: data,
		doFetch: doFetch
	}
}