import axios from 'axios';
import { API } from '../../config';

// POST request
export const post = (url: string, body: any = {}) => {
    const token = localStorage.getItem('auth_token');
    return fetch(`${API}${url}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
		.then((res) => {
				return res.json();
		})
		.catch((err) => {
			return err;
		});
};

// POST request
export const postFile = (url: string, body: any = {}) => {
    const token = localStorage.getItem('auth_token');
    return fetch(`${API}${url}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        },
        body: body,
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

// POST axios request
export const postAxios = (url: string, body: any = {}) => {
    const token = localStorage.getItem('auth_token');
    return axios.post(`${API}${url}`,
    body,
    {
      responseType: 'arraybuffer',
      headers: {
        'Authorization': (token) ? `Bearer ${token.toString()}` : ""
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
};

// GET request
export const get = (url: string) => {
    const token = localStorage.getItem('auth_token');

    return fetch(`${API}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

// GET request
export const getCSV = (url: string) => {
    const token = localStorage.getItem('auth_token');

    return fetch(`${API}${url}`, {
        method: 'GET',
        headers: {
            Accept: 'text/csv',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        }
    })
        .then((res) => {
            return res.text();
        })
        .catch((err) => {
            return err;
        });
};

// PUT request
export const put = (url: string, body: any = {}) => {
    const token = localStorage.getItem('auth_token');

    return fetch(`${API}${url}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};

// DELETE request
export const del = (url: string, body: any = {}) => {
    const token = localStorage.getItem('auth_token');

    return fetch(`${API}${url}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        },
        body: JSON.stringify(body),
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};