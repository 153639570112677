import { useEffect, useState } from "react";
import './DomainPlanEditor.css';
import TableC from "../Table/TableC";
import { Modal, Container } from "react-bootstrap";
import { getPlans } from "../../shared/services/api";
import { PlanData } from "../Plans/Plans";
import { getPlan, getPlanDomainPlan, updatePlanDomains } from "../../shared/services/api";
import { TableColumn, RowActionValues, ServerUpdateResponse } from "../Table/interface";
import { SORT_TYPE } from "../Table/enum";

type DomainPlanEditorProps = {
    open: boolean,
    onClose: () => void,
    planDomainId: number
}


const DomainPlanEditor = ({open, onClose, planDomainId}: DomainPlanEditorProps) => {
    const columns : TableColumn[] = [
        {
            label: 'Name',
            id: 'name',
            type: 'string',
            sort: SORT_TYPE.ASCE
        },
        {
			label: 'Included',
			id: 'in_plan',
			type: 'checkbox',
            filterable: false
		},
        {
			label: 'Link Type',
			id: 'link_type',
			type: 'dropdown',
			map: [
                {label: 'DoFollow', value: 'DoFollow'},
                {label: 'NoFollow', value: 'NoFollow'}
            ]
		},
        {
			label: 'Anchor Text',
			id: 'anchor_text',
			type: 'dropdown',
			map: [
                {label: 'Keep', value: 'keep'},
                {label: 'Natural', value: 'natural'},
                {label: 'URLs', value: 'urls'},
                {label: 'Funnel Name', value: 'funnel_name'},
            ],
            filterable: false
		},
        {
            label: 'DR',
            id: 'dr',
            type: 'number',
        },
        {
            label: 'RD',
            id: 'rd',
            type: 'number',
        },
        {
            label: 'LD',
            id: 'ld',
            type: 'number',
        },
        {
            label: 'Traffic Since',
            id: 'traffic_since',
            type: 'number',
        },
        {
            label: 'Traffic',
            id: 'traffic',
            type: 'number',
        },
        {
            label: 'Old Traffic',
            id: 'old_traffic',
            type: 'number',
        },
        {
            label: 'Traffic Difference',
            id: 'traffic_difference',
            type: 'number',
            subType: 'percent',
        },
        {
            label: 'Niche',
            id: 'niche',
            type: 'string',
        },
        {
            label: 'Countries',
            id: 'countries',
            type: 'string',
        },
        {
            label: 'Final Price',
            id: 'final_price',
            type: 'number',
        },
        {
            label: 'Location',
            id: 'location',
            type: 'string',
        },
        {
            label: 'Language',
            id: 'language',
            type: 'string',
        },
        {
            label: 'Last Updated',
            id: 'updated_at',
            type: 'date',
        },
        {
            label: 'Contact Info',
            id: 'contact_info',
            type: 'string',
        },
        {
            label: 'Continuous/Discontinued',
            id: 'continuous',
            type: 'string'
        },
        {
            label: 'Type',
            id: 'type',
            type: 'string',
        },
        {
            label: 'Currency',
            id: 'currency',
            type: 'string',
        },
        {
            label: 'Bulk Orders',
            id: 'bulk_orders',
            type: 'string',
        },
        {
            label: 'Last 3 Months',
            id: 'last_3_months',
            type: 'string',
        },
        {
            label: 'Last 3 Months Deals',
            id: 'last_3_months_deals',
            type: 'number',
        },
        {
            label: 'Total',
            id: 'total',
            type: 'number',
        }
    ];
    const [plan, setPlan] = useState<PlanData>();
    useEffect(() => {
        if (open && planDomainId) {
            getPlanDomainPlan(planDomainId).then((res) => {
                setPlan(res.plan);
            })
        }
    }, [open, planDomainId])

    const savePlanDomains = (data: Map<number, RowActionValues>) : Promise<ServerUpdateResponse> => {
        return updatePlanDomains(Array.from(data), planDomainId).then((res) => {
            if (!res.error) {
                onClose();
            }
            return new Promise<ServerUpdateResponse>((resolve) => {
                resolve(res);
            })
        })
    }

    return (
        <Modal 
            show={open} 
            closeOnDocumentClick onHide={() => onClose()}  
            dialogClassName='domains-plan-modal' 
            // size='xl'
            animation={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Domain Plan
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(planDomainId ) ? 
                        <TableC type='domain_edit' id={planDomainId} useFilters={true} {...(plan?.id && {prefilter: plan.filterOptions})} editable={false} useGroups={false} allowUpload={false} columns={columns} saveCallback={savePlanDomains} />
                    : <></>
                }
                
            </Modal.Body>
        </Modal>
    );
}

export default DomainPlanEditor;